<template>
    <v-navigation-drawer
      v-model="toogle_filters"
      right
      fixed
      width="500"
      temporary
      :overlay-opacity="0.1"
      class="white--text content-scroll"
    >
      <v-list-item class="py-0 grey lighten-5">
        <v-list-item-content>
          <v-list-item-title class="text-h4 primary--text font-weight-bold">
            <v-icon class="mt-n1" left>mdi-tune</v-icon>
            Filtros Avanzados
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="my-0">
          <v-btn icon @click="toogle_filters = !toogle_filters">
             <v-icon size="20" color="blue-grey">mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-expansion-panels
        v-model="item_filters"
        accordion
        multiple
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="blue-grey--text font-weight-medium">
            <span>Fecha de Cobro</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12"
                class="d-flex"
                :class="{'flex-row align-center': $vuetify.breakpoint.smAndUp, 'flex-column ': $vuetify.breakpoint.xsOnly}"
                >
                <div>
                  <!-- <label-form text="Inicio" required/> -->
                  <fecha-input
                    v-model="range_pago_inicio"
                    color="blue-grey lighten-5"
                    hidden-msg
                    :max="max_date_input_inicio"
                  />
                </div>
                <span class="blue-grey--text font-weight-medium mx-4 caption">a</span>
                <div>
                  <!-- <label-form text="Final" required/> -->
                  <fecha-input
                    v-model="range_pago_fin"
                    color="blue-grey lighten-5"
                    hidden-msg
                    :disabled="range_pago_inicio === ''"
                    :min="range_pago_inicio"
                  />
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="blue-grey--text font-weight-medium">
            <span>Vendedor</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-select
              v-model="vend"
              class="input-filters-advanced"
              :items="ven"
              item-text="ven_des"
              item-value="co_ven"
              outlined
              multiple
              chips
              deletable-chips
              placeholder="Seleccione"
              small-chips
              attach
              clearable
              hide-details
              :menu-props="{ bottom: true, offsetY: true, closeOnContentClick: true }"
            />
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="blue-grey--text font-weight-medium">
            <span>Moneda</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-btn-toggle
              v-model="moneda"
              :value="''"
              color="icono"
              background-color="blue-grey"
            >
              <v-btn value="dolares" class="px-md-6">
                <v-slide-x-transition
                  mode="out-in"
                >
                  <v-icon
                    v-show="moneda === 'dolares'"
                    color="icono"
                    left
                    size="23"
                    v-text="'mdi-check'"
                  />
                </v-slide-x-transition>
                <span>Dolar Americano</span>
              </v-btn>

              <v-btn value="bolivares" class="px-md-6">
                <v-slide-x-transition
                  mode="out-in"
                >
                  <v-icon
                    v-if="moneda === 'bolivares'"
                    color="icono"
                    left
                    size="23"
                    v-text="'mdi-check'"
                  />
                </v-slide-x-transition>
                <span>Bolivar Soberano</span>
              </v-btn>
            </v-btn-toggle>
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
        <v-expansion-panel>
         <v-expansion-panel-header class="blue-grey--text font-weight-medium">
            <span>Estatus</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-checkbox
              v-model="status"
              class="mt-0"
              label="Procesado"
              color="icono"
              hide-details
              value="1"
            />
            <v-checkbox
              v-model="status"
              label="Sin Procesar"
              color="icono"
              hide-details
              value="0"
            />
            <v-checkbox
              v-model="status"
              label="Anulado"
              color="icono"
              hide-details
              value="2"
            />
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="blue-grey--text font-weight-medium">
            <span>Metodo de Pago</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-checkbox
              v-model="metodoPago"
              class="mt-0"
              label="Transferencia"
              color="icono"
              hide-details
              value="transferencia"
            />
            <v-checkbox
              v-model="metodoPago"
              label="Efectivo"
              color="icono"
              hide-details
              value="efectivo"
            />
            <v-checkbox
              v-model="metodoPago"
              label="Pago Móvil"
              color="icono"
              hide-details
              value="pago_movil"
            />
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="blue-grey--text font-weight-medium">
            <span>Banco</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-select
              v-model="banco"
              class="input-filters-advanced"
              :items="bancos"
              item-text="nombre"
              item-value="cod_banco"
              outlined
              chips
              placeholder="Seleccione"
              attach
              clearable
              hide-details
              :menu-props="{ bottom: true, offsetY: true, closeOnContentClick: true }"
            />
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
      </v-expansion-panels>
      <template #append>
        <v-row class="grey lighten-5">
          <v-col cols="10" class="d-flex justify-center align-center px-8 pt-4 pb-8 mx-auto">
            <v-btn class="mx-3 px-8" outlined tile @click="resetear">
              Resetear
            </v-btn>
            <v-btn class="px-8" color="primary" tile @click="aplicarFiltros">
              <v-icon left>mdi-check</v-icon>
              Aplicar
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <!-- <div class="pt-12" /> -->
    </v-navigation-drawer>
</template>
<script>
import moment from 'moment'
export default {
  name:'AvancedFilters',
  components:{
    FechaInput: () => import(
      /* webpackChunkName: "fecha-input" */
      '@/widgets/FechaInput.vue'
    )
  },
  props:{
    value: Boolean,
    ven:{
      type: Array,
      default: () =>([])
    },
    bancos:{
      type: Array,
      default: () =>([])
    }
  },
  data() {
    return{
      selected_order: 'co_art',
      type_order: false,
      item_filters: [],
      toogle_filters: this.value,
      selected_categories: '',
      selected_subcatg: [],

      moneda: '',
      status: [],
      metodoPago: [],
      range_pago_inicio: '',
      range_pago_fin: '',
      max_date_input_inicio: moment().format('YYYY-MM-DD'),
      vend: [],
      banco: '',
    }
  },
  watch: {
    toogle_filters (val, oldVal) {
      if (val === oldVal) return

      this.$emit('input', val)
    },
    value (val, oldVal) {
      if (val === oldVal) return

      this.toogle_filters = val
    },
  },
  methods: {
    aplicarFiltros () {
      this.toogle_filters = false;
      this.$emit('filtrarDatos',
        {
          moneda: typeof this.moneda === 'string' ? this.moneda : '',
          metodo_pago: this.metodoPago,
          estatus: this.status,
          fecha_pago:{
            ini: this.range_pago_inicio,
            fin: this.range_pago_fin,
          },
          vendedor: this.vend,
          banco: this.banco,
        }
      )
    },
    resetear() {
      this.moneda = '';
      this.status = [];
      this.metodoPago = [];
      this.range_pago_inicio = '';
      this.range_pago_fin = '';
      this.vend = [];
      this.banco = '';
    }
  },
}
</script>
<style lang="sass">
.input-filters-advanced .v-input__control fieldset
  background-color: #fafafa !important
</style>
